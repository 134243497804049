var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row", staticStyle: { "padding-top": "1em" } },
    [
      _c("div", { staticClass: "col-md-3 text-center" }, [
        _c("ol", { staticClass: "breadcrumb" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.model.name))])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "btn-group-vertical",
            attrs: { role: "group", "aria-label": "..." }
          },
          [
            _c(
              "a",
              {
                staticClass: "btn btn-default",
                attrs: { href: "/history/tplan/" + this.state.id }
              },
              [_vm._v("История изменений")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-default text-danger",
                on: { click: _vm.delTplan }
              },
              [
                _c("span", { staticClass: "glyphicon glyphicon-trash" }),
                _vm._v(" Удалить\n           ")
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-9" }, [
        _c("form", { staticClass: "form-horizontal" }, [
          _c(
            "div",
            { staticClass: "well well-sm" },
            [
              _c("Input", {
                attrs: {
                  label: "№",
                  size: 7,
                  name: "id",
                  apiTopic: "client",
                  apiPath: _vm.apiPath,
                  disabled: true
                },
                model: {
                  value: _vm.model.id,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "id", $$v)
                  },
                  expression: "model.id"
                }
              }),
              _vm._v(" "),
              _c("Input", {
                attrs: {
                  label: "Название",
                  size: 7,
                  name: "name",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.name,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "name", $$v)
                  },
                  expression: "model.name"
                }
              }),
              _vm._v(" "),
              _c("Select", {
                attrs: {
                  label: "Тип",
                  size: 7,
                  options: _vm.$store.state.preload["/tplan-types"],
                  name: "type",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.type,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "type", $$v)
                  },
                  expression: "model.type"
                }
              }),
              _vm._v(" "),
              _c("Input", {
                attrs: {
                  label: "Описание",
                  size: 7,
                  name: "description",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.description,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "description", $$v)
                  },
                  expression: "model.description"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "well well-sm" },
            [
              _c("Currency", {
                attrs: {
                  label: "Снятие в день",
                  size: 7,
                  name: "per_day",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.per_day,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "per_day", $$v)
                  },
                  expression: "model.per_day"
                }
              }),
              _vm._v(" "),
              _c("Currency", {
                attrs: {
                  label: "Снятие в месяц",
                  size: 7,
                  name: "per_month",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.per_month,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "per_month", $$v)
                  },
                  expression: "model.per_month"
                }
              }),
              _vm._v(" "),
              _c("Select", {
                attrs: {
                  label: "Снятие в минус",
                  size: 7,
                  name: "mandatory_withdraw",
                  options: _vm.yesNoOptions,
                  info:
                    "Снятие производится, даже если у абонента недостаточно средств на балансе",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.mandatory_withdraw,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "mandatory_withdraw", $$v)
                  },
                  expression: "model.mandatory_withdraw"
                }
              }),
              _vm._v(" "),
              _c("Select", {
                attrs: {
                  label: "Снимать с отключенных",
                  size: 7,
                  name: "disabled_user_withdraw",
                  options: _vm.yesNoOptions,
                  info:
                    "Снятие производится, даже если учетная запись абонента отключена",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.disabled_user_withdraw,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "disabled_user_withdraw", $$v)
                  },
                  expression: "model.disabled_user_withdraw"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "well well-sm" },
            [
              _vm._l(_vm.$store.state.preload["/quality-params"], function(
                rec
              ) {
                return [
                  rec.type == "string"
                    ? _c("Input", {
                        attrs: {
                          label: rec.name_ru,
                          size: 7,
                          name: rec.name,
                          apiTopic: "client",
                          apiPath: _vm.apiPath,
                          info: rec.info
                        },
                        model: {
                          value: _vm.model[rec.name],
                          callback: function($$v) {
                            _vm.$set(_vm.model, rec.name, $$v)
                          },
                          expression: "model[rec.name]"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  rec.type == "longstring"
                    ? _c("TextArea", {
                        attrs: {
                          label: rec.name_ru,
                          size: 7,
                          name: rec.name,
                          apiTopic: "client",
                          apiPath: _vm.apiPath,
                          info: rec.info
                        },
                        model: {
                          value: _vm.model[rec.name],
                          callback: function($$v) {
                            _vm.$set(_vm.model, rec.name, $$v)
                          },
                          expression: "model[rec.name]"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  rec.type == "integer"
                    ? _c("Number", {
                        attrs: {
                          label: rec.name_ru,
                          size: 7,
                          name: rec.name,
                          apiTopic: "client",
                          apiPath: _vm.apiPath,
                          info: rec.info,
                          min: rec.min,
                          max: rec.max,
                          step: rec.step
                        },
                        model: {
                          value: _vm.model[rec.name],
                          callback: function($$v) {
                            _vm.$set(_vm.model, rec.name, $$v)
                          },
                          expression: "model[rec.name]"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  rec.type == "darsan-oneof"
                    ? _c("Select", {
                        attrs: {
                          label: rec.name_ru,
                          size: 7,
                          name: rec.name,
                          apiTopic: "client",
                          apiPath: _vm.apiPath,
                          info: rec.info,
                          options: _vm.lists[rec.name],
                          "with-blank": rec.with_blank == "true"
                        },
                        model: {
                          value: _vm.model[rec.name],
                          callback: function($$v) {
                            _vm.$set(_vm.model, rec.name, $$v)
                          },
                          expression: "model[rec.name]"
                        }
                      })
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("span", {
        staticClass: "glyphicon glyphicon-th-list",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v("  "),
      _c("a", { attrs: { href: "/tplan" } }, [_vm._v("Тарифные планы")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }