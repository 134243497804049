<template>
<div>
  <div class="col-md-3 text-center">
    <span class="lead">Новый тариф</span>
  </div>
  
  <div class="col-md-9">
    <form class="form-horizontal">

      <div class="well well-sm">
        <Input label="№" :size=7 v-model="model.id" :error=err.id @input="err.id=null" />
        <Input label="Название" :size=7 v-model="model.name" :error=err.name @input="err.name=null" />
        <Select label="Тип" :size=7 v-model="model.type" :error=err.type :options="$store.state.preload['/tplan-types']" @input="err.type=null" />
        <Input label="Описание" :size=7 v-model="model.description" :error=err.description @input="err.description=null" />
      </div>

      <div class="well well-sm">
        <Currency label="Снятие в день" :size=7 v-model="model.per_day" :error=err.per_day
         @input="err.per_day=null" />
        
        <Currency label="Снятие в месяц" :size=7 v-model="model.per_month" :error=err.per_month
          @input="err.per_month=null" />

        <Select label="Снятие в минус" :size=7 v-model="model.mandatory_withdraw" :error=err.mandatory_withdraw 
        :options=yesNoOptions
        info="Снятие производится, даже если у абонента недостаточно средств на балансе" @input="err.mandatory_withdraw=null" />

        <Select label="Снимать с отключенных" :size=7 v-model="model.disabled_user_withdraw" :error=err.disabled_user_withdraw 
        :options=yesNoOptions
        info="Снятие производится, даже если учетная запись абонента отключена" @input="err.disabled_user_withdraw=null" />
     </div>
     
     <div class="well well-sm">
       <template v-for="rec in $store.state.preload['/quality-params']">
         <Input v-if="rec.type=='string'" :label=rec.name_ru :size=7 :name=rec.name
          :info=rec.info v-model=model[rec.name] :error=err[rec.name] @input="err[rec.name]=null"/>
         <TextArea v-if="rec.type=='longstring'" :label=rec.name_ru :size=7 :name=rec.name
          :info=rec.info v-model=model[rec.name] :error=err[rec.name] @input="err[rec.name]=null" />
         <Number v-if="rec.type=='integer'" :label=rec.name_ru :size=7 :name=rec.name apiTopic=client
          :info=rec.info :min=rec.min :max=rec.max :step=rec.step v-model=model[rec.name] 
          :error=err[rec.name] @input="err[rec.name]=null" />
         <Select v-if="rec.type=='darsan-oneof'" :label=rec.name_ru :size=7 
          :info=rec.info v-model=model[rec.name] :options=lists[rec.name] :with-blank="rec.with_blank=='true'" 
          :error=err[rec.name] @input="err[rec.name]=null" />
       </template>
     </div>

     <div class="btn btn-primary" @click=submit><Icon name=save /> Сохранить</div>

    </form>
  </div>
</div>

</template>

<script>
import darsan from "darsan"
import {notifyError, notifyHttpError} from "common"
import {goto} from "navigation"

import Input from "common/visual/input/Input.vue"
import Select from "common/visual/input/Select.vue"
import Number from "common/visual/input/Number.vue"
import Currency from "common/visual/input/Currency.vue"
import TextArea from "common/visual/input/TextArea.vue"
import SkelInput from "common/visual/SkelInput.vue"

export default {
  name: "TplanNew",
  components: {Input, Select, SkelInput, Number, TextArea, Currency},
  
  data() {
   return {
     ready: true,
     err: {},
     model: {},
     lists: {},
   }
  },
  
  created()
  {
    this.ensure(["tplan-types", "currency", "quality-params"])
    .then(() => 
    {
      this.clearModel()

      for (const rec of this.$store.state.preload['/quality-params'].filter(el=>el.topic && el.path))
      {
        darsan.get("", rec.topic, rec.path).then( list =>
        {
          this.$set(this.lists, rec.name, list)
        })
      }
    })
  },
  
  methods: {
  
    clearModel()
    {
      for (name of Object.keys(this.model))
      {
        this.model[name] = ""
      }
      
      this.model.type = "home"
      this.model.per_day = "0.00"
      this.model.per_month = "0.00"
    },

    validate()
    {
      const error = {} 

      for (const name of ["id", "name"])
      {
        if (!this.model[name])
        {
          error[name] = "Введите значение"
        }
      }
      
      if ( this.model.id && this.model.id.search(/^\d+$/) === -1 ) 
      {
        error.id = "Номер тарифного плана должен состоять только из цифр"
      }

      return error
    },
    
    submit()
    {
      const error = this.validate()

      if (Object.keys(error).length)
      {
        for (const k in error)
        {
          this.$set(this.err, k, error[k])
        }

        return
      }
      
      const data = Object.fromEntries(Object.entries(this.model).filter(([key,el]) => el !== null && el !== undefined))

      darsan.putm("", "client", "/tplan/"+data.id, data)
        .then(resp => 
        {
          this.clearModel()
          goto("/tplan")
        })
        .fail(xhr => 
        {
          const [name, text] = darsan.namedError(xhr)

          if (name)
          {
            this.$set(this.err, name, text)
          }
        });

    },

  },
  
  computed: {
    yesNoOptions()
    {
      return [
        {value: "", text: "Нет"},
        {value: "true", text: "Да"},
      ]
    },

  },
}
</script>
