var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-9" }, [
      _c("form", { staticClass: "form-horizontal" }, [
        _c(
          "div",
          { staticClass: "well well-sm" },
          [
            _c("Input", {
              attrs: { label: "№", size: 7, error: _vm.err.id },
              on: {
                input: function($event) {
                  _vm.err.id = null
                }
              },
              model: {
                value: _vm.model.id,
                callback: function($$v) {
                  _vm.$set(_vm.model, "id", $$v)
                },
                expression: "model.id"
              }
            }),
            _vm._v(" "),
            _c("Input", {
              attrs: { label: "Название", size: 7, error: _vm.err.name },
              on: {
                input: function($event) {
                  _vm.err.name = null
                }
              },
              model: {
                value: _vm.model.name,
                callback: function($$v) {
                  _vm.$set(_vm.model, "name", $$v)
                },
                expression: "model.name"
              }
            }),
            _vm._v(" "),
            _c("Select", {
              attrs: {
                label: "Тип",
                size: 7,
                error: _vm.err.type,
                options: _vm.$store.state.preload["/tplan-types"]
              },
              on: {
                input: function($event) {
                  _vm.err.type = null
                }
              },
              model: {
                value: _vm.model.type,
                callback: function($$v) {
                  _vm.$set(_vm.model, "type", $$v)
                },
                expression: "model.type"
              }
            }),
            _vm._v(" "),
            _c("Input", {
              attrs: { label: "Описание", size: 7, error: _vm.err.description },
              on: {
                input: function($event) {
                  _vm.err.description = null
                }
              },
              model: {
                value: _vm.model.description,
                callback: function($$v) {
                  _vm.$set(_vm.model, "description", $$v)
                },
                expression: "model.description"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "well well-sm" },
          [
            _c("Currency", {
              attrs: {
                label: "Снятие в день",
                size: 7,
                error: _vm.err.per_day
              },
              on: {
                input: function($event) {
                  _vm.err.per_day = null
                }
              },
              model: {
                value: _vm.model.per_day,
                callback: function($$v) {
                  _vm.$set(_vm.model, "per_day", $$v)
                },
                expression: "model.per_day"
              }
            }),
            _vm._v(" "),
            _c("Currency", {
              attrs: {
                label: "Снятие в месяц",
                size: 7,
                error: _vm.err.per_month
              },
              on: {
                input: function($event) {
                  _vm.err.per_month = null
                }
              },
              model: {
                value: _vm.model.per_month,
                callback: function($$v) {
                  _vm.$set(_vm.model, "per_month", $$v)
                },
                expression: "model.per_month"
              }
            }),
            _vm._v(" "),
            _c("Select", {
              attrs: {
                label: "Снятие в минус",
                size: 7,
                error: _vm.err.mandatory_withdraw,
                options: _vm.yesNoOptions,
                info:
                  "Снятие производится, даже если у абонента недостаточно средств на балансе"
              },
              on: {
                input: function($event) {
                  _vm.err.mandatory_withdraw = null
                }
              },
              model: {
                value: _vm.model.mandatory_withdraw,
                callback: function($$v) {
                  _vm.$set(_vm.model, "mandatory_withdraw", $$v)
                },
                expression: "model.mandatory_withdraw"
              }
            }),
            _vm._v(" "),
            _c("Select", {
              attrs: {
                label: "Снимать с отключенных",
                size: 7,
                error: _vm.err.disabled_user_withdraw,
                options: _vm.yesNoOptions,
                info:
                  "Снятие производится, даже если учетная запись абонента отключена"
              },
              on: {
                input: function($event) {
                  _vm.err.disabled_user_withdraw = null
                }
              },
              model: {
                value: _vm.model.disabled_user_withdraw,
                callback: function($$v) {
                  _vm.$set(_vm.model, "disabled_user_withdraw", $$v)
                },
                expression: "model.disabled_user_withdraw"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "well well-sm" },
          [
            _vm._l(_vm.$store.state.preload["/quality-params"], function(rec) {
              return [
                rec.type == "string"
                  ? _c("Input", {
                      attrs: {
                        label: rec.name_ru,
                        size: 7,
                        name: rec.name,
                        info: rec.info,
                        error: _vm.err[rec.name]
                      },
                      on: {
                        input: function($event) {
                          _vm.err[rec.name] = null
                        }
                      },
                      model: {
                        value: _vm.model[rec.name],
                        callback: function($$v) {
                          _vm.$set(_vm.model, rec.name, $$v)
                        },
                        expression: "model[rec.name]"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                rec.type == "longstring"
                  ? _c("TextArea", {
                      attrs: {
                        label: rec.name_ru,
                        size: 7,
                        name: rec.name,
                        info: rec.info,
                        error: _vm.err[rec.name]
                      },
                      on: {
                        input: function($event) {
                          _vm.err[rec.name] = null
                        }
                      },
                      model: {
                        value: _vm.model[rec.name],
                        callback: function($$v) {
                          _vm.$set(_vm.model, rec.name, $$v)
                        },
                        expression: "model[rec.name]"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                rec.type == "integer"
                  ? _c("Number", {
                      attrs: {
                        label: rec.name_ru,
                        size: 7,
                        name: rec.name,
                        apiTopic: "client",
                        info: rec.info,
                        min: rec.min,
                        max: rec.max,
                        step: rec.step,
                        error: _vm.err[rec.name]
                      },
                      on: {
                        input: function($event) {
                          _vm.err[rec.name] = null
                        }
                      },
                      model: {
                        value: _vm.model[rec.name],
                        callback: function($$v) {
                          _vm.$set(_vm.model, rec.name, $$v)
                        },
                        expression: "model[rec.name]"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                rec.type == "darsan-oneof"
                  ? _c("Select", {
                      attrs: {
                        label: rec.name_ru,
                        size: 7,
                        info: rec.info,
                        options: _vm.lists[rec.name],
                        "with-blank": rec.with_blank == "true",
                        error: _vm.err[rec.name]
                      },
                      on: {
                        input: function($event) {
                          _vm.err[rec.name] = null
                        }
                      },
                      model: {
                        value: _vm.model[rec.name],
                        callback: function($$v) {
                          _vm.$set(_vm.model, rec.name, $$v)
                        },
                        expression: "model[rec.name]"
                      }
                    })
                  : _vm._e()
              ]
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn btn-primary", on: { click: _vm.submit } },
          [_c("Icon", { attrs: { name: "save" } }), _vm._v(" Сохранить")],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-3 text-center" }, [
      _c("span", { staticClass: "lead" }, [_vm._v("Новый тариф")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }