<template>
  <div class="row" style="padding-top: 1em">
    <div class="col-md-3 text-center">
        <ol class="breadcrumb">
            <li><span class="glyphicon glyphicon-th-list" aria-hidden="true"></span>&nbsp;&nbsp;<a href="/tplan">Тарифные планы</a></li>
            <li class="active">{{model.name}}</li>
        </ol>

        <div class="btn-group-vertical" role="group" aria-label="...">
            <a class="btn btn-default" :href="'/history/tplan/'+this.state.id">История изменений</a>
            <a class="btn btn-default text-danger" @click=delTplan >
                <span class="glyphicon glyphicon-trash"></span> Удалить
            </a>
        </div>
    </div>

 <div class="col-md-9">
    <form class="form-horizontal">

      <div class="well well-sm">
        <Input label="№" :size=7 v-model="model.id" name=id apiTopic=client :apiPath=apiPath :disabled=true />
        <Input label="Название" :size=7 v-model="model.name" name=name apiTopic=client :apiPath=apiPath />
        <Select label="Тип" :size=7 v-model="model.type" :options="$store.state.preload['/tplan-types']" name=type apiTopic=client :apiPath=apiPath />
        <Input label="Описание" :size=7 v-model="model.description" name=description apiTopic=client :apiPath=apiPath />
      </div>

      <div class="well well-sm">
        <Currency label="Снятие в день" :size=7 v-model="model.per_day" name="per_day"
         apiTopic=client :apiPath=apiPath />
        
        <Currency label="Снятие в месяц" :size=7 v-model="model.per_month" name="per_month"
         apiTopic=client :apiPath=apiPath />

        <Select label="Снятие в минус" :size=7 v-model="model.mandatory_withdraw" name=mandatory_withdraw 
        :options=yesNoOptions
        info="Снятие производится, даже если у абонента недостаточно средств на балансе" apiTopic=client :apiPath=apiPath />

        <Select label="Снимать с отключенных" :size=7 v-model="model.disabled_user_withdraw" name=disabled_user_withdraw 
        :options=yesNoOptions
        info="Снятие производится, даже если учетная запись абонента отключена" apiTopic=client :apiPath=apiPath />
     </div>
     
     <div class="well well-sm">
       <template v-for="rec in $store.state.preload['/quality-params']">
         <Input v-if="rec.type=='string'" :label=rec.name_ru :size=7 :name=rec.name apiTopic=client :apiPath=apiPath
          :info=rec.info v-model=model[rec.name] />
         <TextArea v-if="rec.type=='longstring'" :label=rec.name_ru :size=7 :name=rec.name apiTopic=client :apiPath=apiPath
          :info=rec.info v-model=model[rec.name] />
         <Number v-if="rec.type=='integer'" :label=rec.name_ru :size=7 :name=rec.name apiTopic=client :apiPath=apiPath
          :info=rec.info :min=rec.min :max=rec.max :step=rec.step v-model=model[rec.name] />
         <Select v-if="rec.type=='darsan-oneof'" :label=rec.name_ru :size=7 :name=rec.name apiTopic=client :apiPath=apiPath
          :info=rec.info v-model=model[rec.name] :options=lists[rec.name] :with-blank="rec.with_blank=='true'" />
       </template>
     </div>

    </form>
  </div>
  </div>
  
</template>

<script>

import Select from "common/visual/cooper/Select.vue"
import Input from "common/visual/cooper/Input.vue"
import Number from "common/visual/cooper/Number.vue"
import Currency from "common/visual/cooper/Currency.vue"
import TextArea from "common/visual/cooper/TextArea.vue"
import SkelInput from "common/visual/SkelInput.vue"
import {notifyHttpError} from "common"
import darsan from "darsan"
import {goto} from "navigation"

export default {
  name: "TplanEditor",
  
  components: {Select, Input, SkelInput, Number, TextArea, Currency},

  props: {
    state: Object,
  },
  
  created()
  {
    this.ensure(["quality-params", "tplan-types","currency"]).then(() =>
    {
      for (const rec of this.$store.state.preload['/quality-params'].filter(el=>el.topic && el.path))
      {
        darsan.get("", rec.topic, rec.path).then( list =>
        {
          this.$set(this.lists, rec.name, list)
        })
      }
    })
  },
  
  data()
  {
    return {
      model: {},
      err: {},
      lists: {},
    }
  },
  
  activated()
  {
    Promise.all([
      darsan.get("", "client", this.apiPath)
    ])
    .then(([rec]) => 
    {
      for (const name of Object.keys(rec))
      {
        if (name=="disabled_user_withdraw" || name=="mandatory_withdraw")
        {
          this.$set(this.model, name, rec[name] ? "true" : "")
        }
        else
        {
          this.$set(this.model, name, rec[name])
        }
      }
    })
    .catch(xhr => notifyHttpError(xhr))
  },
  
  methods: {
    delTplan()
    {
      if ( confirm("Вы уверены, что хотите удалить тарифный план '" + this.model.name + "' ?" ) ) 
      {
        darsan.delete("", "client", "/tplan/" + this.state.id)
        .done(() => goto("/tplan"))
      }
    }
  },
  
  computed: {
    apiPath()
    {
      return "/tplan/"+this.state.id
    },

    yesNoOptions()
    {
      return [
        {value: "", text: "Нет"},
        {value: "true", text: "Да"},
      ]
    },

  },
}
</script>
