<template>
  <keep-alive>
    <component :is=currentComponent :state=state />
  </keep-alive>
</template>

<script>

import TplanNew from "tplan/page/TplanNew.vue"
import TplanTable from "tplan/page/TplanTable.vue"
import TplanEditor from "tplan/page/TplanEditor.vue"

import {eventBus} from "main"

export default {
  name: "TplanPage",
  title: "Тарифные планы",
  components: {},
  
  data()
  {
    return {
      currentComponent: null,
      state: {
        _rest: "",
        _tail: "",
        subpath: "",
        id: "",
      },
    }
  },
  
  created()
  {
    eventBus.on("routeChanged", ({path,params}) => this.setState(path,params))
  },
  
  methods: {
    setState(path,params)
    {
      if (path.match(/\/tplan\/\d+/))
      {
        this.currentComponent = TplanEditor
      }
      else if (path=="/tplan/!new")
      {
        this.currentComponent = TplanNew
      }
      else
      {
        this.currentComponent = TplanTable
      }

      // state      
      const s = {}

      const parts = path.split("/")
      s._rest = "/" + parts.slice(3).join("/")

      s.id = parts[2]
      s.subpath = parts[3]
      s._tail = parts[4]

      s.params = params
      for (const p of new URLSearchParams(params))
      {
        s[ p[0] ] =  p[1]
      }
      
      
      this.state = s
    },

  },

}

</script>
